:root {
  --fontFamily: "Nunito", sans-serif;
  --mainColor: #ee3a43;
  --whiteColor: #ffffff;
  --blackColor: #221638;
  --optionalColor: #6b6b84;
  --fontSize: 17px;
  --transition: .5s;
}

.bg-white-theme {
  background-color: #fff !important;
  background: linear-gradient(45deg, transparent 85%, #ee3a43 85%);
}

.top-main {
  background-image: linear-gradient(81deg, #ee3a43 0%, #f2313a 75%, #eb575e 100%);
}

body {
  padding: 0;
  margin: 0;
  font-size: var(--fontSize);
  font-family: var(--fontFamily);
}

.container {
  max-width: 1230px;
}

img {
  max-width: 100%;
  height: auto;
}

:focus {
  outline: 0 !important;
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: var(--fontFamily);
  font-weight: 800;
}

p {
  color: var(--optionalColor);
  line-height: 1.8;
  margin-bottom: 15px;
  font-weight: 600;
  font-size: var(--fontSize);
}
p:last-child {
  margin-bottom: 0;
}

a {
  color: var(--blackColor);
  text-decoration: none;
  transition: var(--transition);
}
a:hover {
  color: var(--mainColor);
  text-decoration: none;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.bg-e3fbff {
  background-color: #e3fbff;
}

.bg-f1f8fb {
  background-color: #f1f8fb;
}

.bg-fffbf5 {
  background-color: #fffbf5;
}

.bg-fafafb {
  background-color: #fafafb;
}

.bg-fffaf3 {
  background-color: #fffaf3;
}

/*default-btn*/
.default-btn {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: var(--transition);
  border-radius: 5px;
  padding: 12px 25px 12px 50px;
  font-weight: 700;
  font-size: 16px;
}
.default-btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--blackColor);
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px;
}
.default-btn i {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 16.5px;
}
.default-btn i.flaticon-user {
  margin-top: -1.5px;
}
.default-btn:hover {
  color: var(--whiteColor);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transform: translateY(-5px);
}
.default-btn:hover span {
  width: 225%;
  height: 562.5px;
}

/*section-title*/
.section-title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 55px;
}
.section-title .sub-title {
  position: relative;
  display: block;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: var(--mainColor);
  font-weight: 700;
  font-size: 15px;
}
.section-title .sub-title img {
  margin-right: 5px;
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.section-title h2 {
  margin-bottom: 0;
  position: relative;
  display: inline-block;
  font-size: 44px;
}
.section-title h2 .overlay {
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--blackColor);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.section-title p {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
}

/*form-control*/
.form-control {
  height: 50px;
  color: var(--blackColor);
  box-shadow: unset !important;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  transition: var(--transition);
  border-radius: 3px;
  padding: 1px 0 0 15px;
  font-size: 16px;
  font-weight: 600;
}
.form-control::placeholder {
  color: #7e7e7e;
  transition: var(--transition);
}
.form-control:focus {
  border-color: var(--mainColor);
  background-color: transparent;
}
.form-control:focus::placeholder {
  color: transparent;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
}

/*owl-carousel*/
.case-study-slides.owl-carousel .owl-nav {
  margin: 0;
}
.case-study-slides.owl-carousel .owl-nav .owl-prev, .case-study-slides.owl-carousel .owl-nav .owl-next {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--optionalColor);
  transition: var(--transition);
  padding: 0;
  font-size: 45px;
  opacity: 0;
  visibility: hidden;
  background-color: transparent !important;
  line-height: 1;
}
.case-study-slides.owl-carousel .owl-nav .owl-prev:hover, .case-study-slides.owl-carousel .owl-nav .owl-next:hover {
  color: var(--mainColor);
}
.case-study-slides.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: 100px;
}
.case-study-slides.owl-carousel:hover .owl-nav .owl-prev, .case-study-slides.owl-carousel:hover .owl-nav .owl-next {
  left: 50px;
  opacity: 1;
  visibility: visible;
}
.case-study-slides.owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: 50px;
}

.testimonials-slides.owl-carousel .owl-nav {
  margin: 0;
}
.testimonials-slides.owl-carousel .owl-nav .owl-prev, .testimonials-slides.owl-carousel .owl-nav .owl-next {
  position: absolute;
  left: 0;
  width: 40px;
  top: -20px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--whiteColor) !important;
  color: var(--blackColor);
  border: 1px solid #eae2d7;
  line-height: 40px;
  transition: var(--transition);
  padding: 0;
  font-size: 16px;
}
.testimonials-slides.owl-carousel .owl-nav .owl-prev:hover, .testimonials-slides.owl-carousel .owl-nav .owl-next:hover {
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: var(--whiteColor);
}
.testimonials-slides.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: 0;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  position: relative;
  z-index: 1;
  background: transparent url(assets/img/page-title-bg.jpg) right top no-repeat;
  padding-top: 200px;
  padding-bottom: 100px;
}
.page-title-area .shape-img1 {
  top: auto;
  bottom: 25%;
  left: 5%;
}
.page-title-area.page-title-style-two {
  padding-top: 120px;
  padding-bottom: 120px;
}

.page-title-content h2 {
  margin-bottom: 0;
  font-size: 44px;
}
.page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 15px;
}
.page-title-content ul li {
  display: inline-block;
  margin-right: 25px;
  position: relative;
  font-size: 16.5px;
  font-weight: 700;
}
.page-title-content ul li a {
  display: inline-block;
}
.page-title-content ul li:last-child {
  margin-right: 0;
}
.page-title-content ul li:last-child::before {
  display: none;
}
.page-title-content ul li::before {
  color: var(--mainColor);
  position: absolute;
  right: -18px;
  top: 5.5px;
  line-height: 1;
  content: "\f104";
  font-family: Flaticon;
  font-size: 11px;
}
.page-title-content.text-center ul li {
  margin-left: 13px;
  margin-right: 13px;
}
.page-title-content.text-center ul li:first-child {
  margin-left: 0;
}

/*================================================
Shape Img CSS
=================================================*/
.shape-img1 {
  position: absolute;
  left: 10%;
  top: 2%;
  z-index: -1;
}
.shape-img1 img {
  animation-name: rotateme;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.shape-img2 {
  position: absolute;
  bottom: 10%;
  z-index: -1;
  right: 30px;
  animation: rotate3d 4s linear infinite;
  opacity: 0.25;
}

.shape-img3 {
  position: absolute;
  left: 20%;
  top: 5%;
  z-index: -1;
  animation: movebounce 5s linear infinite;
  opacity: 0.15;
}

@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.footer-map {
  position: absolute;
  right: 20%;
  top: 40%;
  transform: translateY(-40%);
  z-index: -1;
  opacity: 0.8;
}
.footer-map img {
  animation: moveleftbounce 5s linear infinite;
}

@keyframes moveleftbounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes animeCircleSpin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(0);
  }
}
.circle-shape2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.vector-shape7 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.vector-shape8 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.circle-shape1 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: -1;
}
.lines .line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #e5ebf0;
  overflow: hidden;
}
.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, var(--mainColor) 75%, var(--mainColor) 100%);
  animation: run 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.lines .line:nth-child(1) {
  margin-left: -30%;
}
.lines .line:nth-child(1)::after {
  animation-delay: 2s;
}
.lines .line:nth-child(3) {
  margin-left: 30%;
}
.lines .line:nth-child(3)::after {
  animation-delay: 2.5s;
}
.lines .line:nth-child(4) {
  margin-left: -15%;
}
.lines .line:nth-child(4)::after {
  animation-delay: 3s;
}
.lines .line:nth-child(5) {
  margin-left: 15%;
}
.lines .line:nth-child(5)::after {
  animation-delay: 3.5s;
}

@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
.vector-shape4 {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.vector-shape4 img {
  animation: movebounce 5s linear infinite;
}

.vector-shape5 {
  position: absolute;
  left: 15%;
  bottom: -7%;
  z-index: -1;
}
.vector-shape5 img {
  animation-name: rotateme;
  animation-duration: 350s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.map-shape1 {
  position: absolute;
  right: 15%;
  top: 0;
  z-index: -1;
  opacity: 0.5;
}
.map-shape1 img {
  animation-name: rotatemetwo;
  animation-duration: 300s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes movebounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.shape-img1 {
  position: absolute;
  left: 10%;
  top: 2%;
  z-index: -1;
}
.shape-img1 img {
  animation-name: rotateme;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.divider {
  width: 100%;
  position: absolute;
  height: 100px;
  pointer-events: none;
  -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
  -webkit-mask-size: 100% 101%;
  mask-size: 100% 101%;
  background: #fffaf3;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.divider.white-color {
  background-color: var(--whiteColor);
}

.vector-shape1 {
  position: absolute;
  left: 12%;
  z-index: -1;
  bottom: 40px;
}
.vector-shape1 img {
  animation-name: rotatemetwo;
  animation-duration: 350s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.vector-shape2 {
  position: absolute;
  right: 10%;
  top: 230px;
  z-index: -1;
}
.vector-shape2 img {
  animation-name: rotateme;
  animation-duration: 350s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotatemetwo {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.vector-shape3 {
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: -1;
}

.vector-shape9 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.vector-shape10 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.vector-shape6 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.7;
}
.vector-shape6 img {
  animation: movebounce 5s linear infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.vector-shape6 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.7;
}
.vector-shape6 img {
  animation: movebounce 5s linear infinite;
}

.vector-shape11 {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.vector-shape11 img {
  animation: movebounce 5s linear infinite;
}

.map-shape2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: -1;
}
.map-shape2 img {
  animation-name: rotateme;
  animation-duration: 200s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.scientist-area {
  position: relative;
  z-index: 1;
}
.scientist-area.bg-color::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  right: 0;
  background-color: #f1f8fb;
  height: 247px;
}

.process-area {
  position: relative;
  z-index: 1;
}

.pagination-area {
  margin-top: 35px;
}
.pagination-area .page-numbers {
  width: 40px;
  height: 40px;
  background-color: var(--whiteColor);
  box-shadow: 0 0px 30px 5px #e4e4ee;
  color: var(--blackColor);
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 41px;
  position: relative;
  margin-left: 5px;
  margin-right: 5px;
  font-size: var(--fontSize);
  font-weight: 800;
}
.pagination-area .page-numbers:hover, .pagination-area .page-numbers.current {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.pagination-area .page-numbers i {
  position: relative;
  top: 1.5px;
}

.widget-area {
  padding-left: 15px;
}
.widget-area.widget-left-sidebar {
  padding-right: 15px;
  padding-left: 0;
}
.widget-area .widget {
  margin-bottom: 40px;
}
.widget-area .widget:last-child {
  margin-bottom: 0;
}
.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 7px;
  border-bottom: 1px solid #eeeeee;
  font-size: 22px;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: var(--mainColor);
}
.widget-area .widget_search form {
  position: relative;
}
.widget-area .widget_search form .screen-reader-text {
  display: none;
}
.widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.widget-area .widget_search form .search-field {
  height: 50px;
  color: var(--blackColor);
  background-color: #f2f4f5;
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 0 0 0 15px;
  border: none;
  transition: var(--transition);
  font-size: var(--fontSize);
  font-weight: 500;
}
.widget-area .widget_search form .search-field::placeholder {
  color: var(--optionalColor);
  transition: var(--transition);
}
.widget-area .widget_search form .search-field:focus::placeholder {
  color: transparent;
}
.widget-area .widget_search form button {
  border: none;
  background-color: transparent;
  color: var(--mainColor);
  height: 40px;
  width: 40px;
  position: absolute;
  right: 5px;
  padding: 0;
  transition: var(--transition);
  top: 5px;
  font-size: 20px;
}
.widget-area .widget_search form button i {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.widget-area .widget_search form button:hover, .widget-area .widget_search form button:focus {
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_tracer_posts_thumb {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_tracer_posts_thumb .item {
  overflow: hidden;
  margin-bottom: 15px;
}
.widget-area .widget_tracer_posts_thumb .item:last-child {
  margin-bottom: 0;
}
.widget-area .widget_tracer_posts_thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_tracer_posts_thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_tracer_posts_thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/blog/img9.jpg);
}
.widget-area .widget_tracer_posts_thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/blog/img8.jpg);
}
.widget-area .widget_tracer_posts_thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/blog/img7.jpg);
}
.widget-area .widget_tracer_posts_thumb .item .thumb::before, .widget-area .widget_tracer_posts_thumb .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_tracer_posts_thumb .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_tracer_posts_thumb .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_tracer_posts_thumb .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_tracer_posts_thumb .item .info span {
  display: block;
  color: var(--optionalColor);
  text-transform: uppercase;
  margin-top: -2px;
  margin-bottom: 5px;
  font-size: 13px;
}
.widget-area .widget_tracer_posts_thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
  font-weight: 800;
}
.widget-area .widget_tracer_posts_thumb .item .info .title a {
  display: inline-block;
}
.widget-area .widget_tracer_posts_thumb .item:hover .thumb::before, .widget-area .widget_tracer_posts_thumb .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_categories ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories ul li {
  position: relative;
  margin-bottom: 12px;
  overflow: hidden;
  color: var(--blackColor);
  padding-left: 18px;
  font-weight: 700;
  font-size: 15px;
}
.widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
}
.widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  height: 8px;
  width: 8px;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 7px;
  position: absolute;
}
.widget-area .widget_categories ul li a {
  color: var(--blackColor);
  display: inline-block;
}
.widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}
.widget-area .widget_categories ul li .post-count {
  font-size: 14px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 17px;
}
.widget-area .tagcloud a {
  display: inline-block;
  background: #f5f5f5;
  color: var(--blackColor);
  padding: 7px 15px;
  border: none;
  border-radius: 3px;
  font-weight: 700;
  font-size: 14px !important;
  margin-top: 8px;
  margin-right: 4px;
}
.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.widget-area .widget_popular_products {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_popular_products .widget-title {
  margin-bottom: 30px;
}
.widget-area .widget_popular_products .item {
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}
.widget-area .widget_popular_products .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.widget-area .widget_popular_products .item .thumb {
  float: left;
  height: 110px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 110px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_popular_products .item .thumb .fullimage {
  width: 110px;
  height: 110px;
  display: inline-block;
  border-radius: 3px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_popular_products .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/products/img7.jpg);
}
.widget-area .widget_popular_products .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/products/img8.jpg);
}
.widget-area .widget_popular_products .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/products/img8.jpg);
}
.widget-area .widget_popular_products .item .thumb::before, .widget-area .widget_popular_products .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_popular_products .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_popular_products .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_popular_products .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_popular_products .item .info span {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 6px;
  margin-top: -5px;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
}
.widget-area .widget_popular_products .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
  font-weight: 800;
}
.widget-area .widget_popular_products .item .info .title a {
  display: inline-block;
}
.widget-area .widget_popular_products .item .info .rating {
  margin-top: 8px;
}
.widget-area .widget_popular_products .item .info .rating i {
  color: #f6b500;
  display: inline-block;
  margin-right: -2px;
}
.widget-area .widget_popular_products .item:hover .thumb::before, .widget-area .widget_popular_products .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .filter_by_price .irs--flat .irs-bar {
  background-color: var(--mainColor);
}
.widget-area .filter_by_price .irs--flat .irs-handle > i:first-child {
  background-color: var(--mainColor);
}
.widget-area .filter_by_price .irs--flat .irs-from, .widget-area .filter_by_price .irs--flat .irs-to, .widget-area .filter_by_price .irs--flat .irs-single {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_recent_courses {
  position: relative;
  overflow: hidden;
}
.widget-area .widget_recent_courses .widget-title {
  margin-bottom: 30px;
}
.widget-area .widget_recent_courses .item {
  overflow: hidden;
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
}
.widget-area .widget_recent_courses .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.widget-area .widget_recent_courses .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 80px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_recent_courses .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  border-radius: 3px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_recent_courses .item .thumb .fullimage.bg1 {
  background-image: url(assets/img/courses/img1.jpg);
}
.widget-area .widget_recent_courses .item .thumb .fullimage.bg2 {
  background-image: url(assets/img/courses/img2.jpg);
}
.widget-area .widget_recent_courses .item .thumb .fullimage.bg3 {
  background-image: url(assets/img/courses/img3.jpg);
}
.widget-area .widget_recent_courses .item .thumb::before, .widget-area .widget_recent_courses .item .thumb::after {
  transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: var(--whiteColor);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
}
.widget-area .widget_recent_courses .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_recent_courses .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_recent_courses .item .info {
  overflow: hidden;
  margin-top: 5px;
}
.widget-area .widget_recent_courses .item .info span {
  display: block;
  color: var(--mainColor);
  text-transform: uppercase;
  margin-bottom: 6px;
  margin-top: -5px;
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
}
.widget-area .widget_recent_courses .item .info .title {
  margin-bottom: 0;
  line-height: 1.4;
  font-size: 17px;
  font-weight: 800;
}
.widget-area .widget_recent_courses .item .info .title a {
  display: inline-block;
}
.widget-area .widget_recent_courses .item:hover .thumb::before, .widget-area .widget_recent_courses .item:hover .thumb::after {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.widget-area .widget_insight ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_insight ul li {
  margin-bottom: -1px;
}
.widget-area .widget_insight ul li a {
  position: relative;
  display: block;
  padding: 15px 25px 15px 40px;
  color: var(--optionalColor);
  border: 1px solid #eeeeee;
  font-size: var(--fontSize);
  font-weight: 700;
}
.widget-area .widget_insight ul li a::before {
  width: 5px;
  height: 5px;
  transition: var(--transition);
  background-color: var(--optionalColor);
  content: "";
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.widget-area .widget_insight ul li a:hover, .widget-area .widget_insight ul li a.active {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_insight ul li a:hover::before, .widget-area .widget_insight ul li a.active::before {
  background-color: var(--whiteColor);
}
.widget-area .widget_insight ul li.active a {
  border-color: var(--mainColor);
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.widget-area .widget_insight ul li.active a::before {
  background-color: var(--whiteColor);
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }
  .container {
    max-width: 100%;
  }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .ptb-70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .pt-70 {
    padding-top: 30px;
  }
  .pb-70 {
    padding-bottom: 30px;
  }
  .default-btn {
    padding: 11px 20px 11px 40px;
    font-size: 15px;
  }
  .default-btn i {
    left: 19px;
    font-size: 14px;
  }
  .section-title {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .section-title .sub-title {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 27px;
  }
  .section-title p {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
  .form-control {
    height: 45px;
    font-size: 14.5px;
  }
  .lines {
    display: none;
  }
  .vector-shape1, .vector-shape2, .vector-shape3, .vector-shape4, .vector-shape5, .vector-shape6, .vector-shape7, .vector-shape8, .vector-shape9, .vector-shape10, .vector-shape11, .vector-shape12, .circle-shape1, .circle-shape2, .circle-shape3, .circle-shape4, .map-shape1, .map-shape2 {
    display: none !important;
  }
  .case-study-slides.owl-carousel .owl-nav .owl-prev, .case-study-slides.owl-carousel .owl-nav .owl-next {
    left: 15px !important;
    font-size: 30px;
    top: auto;
    transform: unset;
    bottom: 20px;
    opacity: 1;
    visibility: visible;
  }
  .case-study-slides.owl-carousel .owl-nav .owl-prev:hover, .case-study-slides.owl-carousel .owl-nav .owl-next:hover {
    color: var(--mainColor);
  }
  .case-study-slides.owl-carousel .owl-nav .owl-next {
    left: auto !important;
    right: 15px !important;
  }
  .divider {
    display: none;
  }
  .testimonials-slides.owl-carousel {
    margin-bottom: 30px;
  }
  .testimonials-slides.owl-carousel .owl-nav {
    text-align: center;
  }
  .testimonials-slides.owl-carousel .owl-nav .owl-prev, .testimonials-slides.owl-carousel .owl-nav .owl-next {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    right: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .footer-map {
    display: none;
  }
  .page-title-area {
    background-position: center center;
    padding-top: 150px;
    padding-bottom: 60px;
  }
  .page-title-area.page-title-style-two {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .page-title-content {
    text-align: center;
  }
  .page-title-content h2 {
    font-size: 28px;
  }
  .page-title-content ul {
    margin-top: 12px;
  }
  .page-title-content ul li {
    font-size: 14.5px;
    margin-left: 13px;
    margin-right: 13px;
  }
  .page-title-content ul li:first-child {
    margin-left: 0;
  }
  .page-title-content ul li::before {
    top: 4.5px;
    font-size: 10px;
  }
  .shape-img1, .shape-img2, .shape-img3 {
    display: none;
  }
  .pagination-area {
    margin-top: 20px;
  }
  .pagination-area .page-numbers {
    width: 35px;
    height: 35px;
    line-height: 37px;
    font-size: 15px;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 40px;
  }
  .widget-area .widget .widget-title {
    font-size: 19px;
  }
  .widget-area .widget_recent_courses .item .info span {
    font-size: 15px;
  }
  .widget-area .widget_recent_courses .item .info .title {
    font-size: 15px;
  }
  .widget-area .widget_popular_products .item .info span {
    font-size: 15px;
  }
  .widget-area .widget_popular_products .item .info .title {
    font-size: 15px;
  }
  .widget-area .widget_insight ul li a {
    font-size: 15px;
  }
  .widget-area .widget_tracer_posts_thumb .item .info .title {
    font-size: 15px;
  }
  .widget-area.widget-left-sidebar {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  .container {
    max-width: 720px;
  }
  .ptb-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-100 {
    padding-top: 80px;
  }
  .pb-100 {
    padding-bottom: 80px;
  }
  .ptb-70 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .pt-70 {
    padding-top: 50px;
  }
  .pb-70 {
    padding-bottom: 50px;
  }
  .section-title {
    max-width: 600px;
    margin-bottom: 45px;
  }
  .section-title .sub-title {
    font-size: 14px;
  }
  .section-title h2 {
    font-size: 35px;
  }
  .section-title p {
    max-width: 500px;
  }
  .default-btn {
    font-size: 15px;
  }
  .vector-shape1, .vector-shape2, .vector-shape3, .vector-shape4, .vector-shape5, .vector-shape6, .vector-shape7, .vector-shape8, .vector-shape9, .vector-shape10, .vector-shape11, .vector-shape12, .circle-shape1, .circle-shape2, .circle-shape3, .circle-shape4, .map-shape1, .map-shape2 {
    display: none !important;
  }
  .footer-map {
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
  }
  .vector-shape4, .vector-shape5, .map-shape1 {
    display: none;
  }
  .divider {
    display: none;
  }
  .testimonials-slides.owl-carousel {
    margin-bottom: 30px;
  }
  .testimonials-slides.owl-carousel .owl-nav {
    text-align: center;
  }
  .testimonials-slides.owl-carousel .owl-nav .owl-prev, .testimonials-slides.owl-carousel .owl-nav .owl-next {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    right: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .vector-shape1, .vector-shape2, .vector-shape3 {
    display: none;
  }
  .vector-shape6 {
    display: none;
  }
  .vector-shape11 {
    display: none;
  }
  .page-title-area {
    background-position: center center;
    padding-bottom: 80px;
    padding-top: 160px;
  }
  .page-title-area.page-title-style-two {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .page-title-content {
    text-align: center;
  }
  .page-title-content h2 {
    font-size: 38px;
  }
  .page-title-content ul li {
    margin-left: 10px;
    margin-right: 15px;
  }
  .page-title-content ul li:first-child {
    margin-left: 0;
  }
  .widget-area {
    padding-left: 0;
    margin-top: 35px;
  }
  .widget-area.widget-left-sidebar {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 35px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body {
    font-size: 16px;
  }
  p {
    font-size: 16px;
  }
  .container {
    max-width: 960px;
  }
  .section-title {
    max-width: 700px;
    margin-bottom: 50px;
  }
  .section-title h2 {
    font-size: 36px;
  }
  .default-btn {
    font-size: 15px;
  }
  .footer-map {
    right: auto;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 0.55;
  }
  .vector-shape3 {
    display: none !important;
  }
  .vector-shape7 img {
    max-width: 60%;
  }
  .vector-shape8 {
    display: none !important;
  }
  .vector-shape6 {
    display: none !important;
  }
  .circle-shape1, .circle-shape2, .lines {
    display: none !important;
  }
  .case-study-slides.owl-carousel .owl-nav {
    position: absolute;
    right: 25px;
    top: 30px;
  }
  .case-study-slides.owl-carousel .owl-nav .owl-prev, .case-study-slides.owl-carousel .owl-nav .owl-next {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: translateY(0);
    margin-left: 5px;
    font-size: 25px;
    opacity: 1;
    visibility: visible;
  }
  .case-study-slides.owl-carousel .owl-nav .owl-next {
    right: 0 !important;
  }
  .vector-shape4, .vector-shape5 {
    display: none !important;
  }
  .testimonials-slides.owl-carousel {
    margin-bottom: 30px;
  }
  .testimonials-slides.owl-carousel .owl-nav {
    text-align: center;
  }
  .testimonials-slides.owl-carousel .owl-nav .owl-prev, .testimonials-slides.owl-carousel .owl-nav .owl-next {
    position: relative;
    left: 0 !important;
    top: 0;
    transform: unset;
    right: 0 !important;
    margin-left: 5px;
    margin-right: 5px;
  }
  .divider {
    height: 70px;
  }
  .vector-shape1, .vector-shape2 {
    display: none !important;
  }
  .page-title-area {
    padding-top: 160px;
    padding-bottom: 80px;
  }
  .page-title-area.page-title-style-two {
    padding-bottom: 90px;
    padding-top: 80px;
  }
  .page-title-content {
    text-align: center;
  }
  .page-title-content h2 {
    font-size: 40px;
  }
  .page-title-content ul li {
    margin-left: 14px;
    margin-right: 14px;
  }
  .page-title-content ul li:first-child {
    margin-left: 0;
  }
  .widget-area {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1355px) {
  .container {
    max-width: 1140px;
  }
  .circle-shape1 {
    display: none;
  }
}